import React, { useContext, useEffect, useState } from "react"
import Layout from "@components/layout"
import { SpaceContext } from "@hooks/SpaceContext"
import InventoryAnalytics from "../../../components/Tabs/InventoryAnalytics"
import get from "lodash/get"

const InventoryAnalyticsTab = () => {
  const {
    data: { allOffices, locationsCategorizedBySpaceOperator, soData, allDesks },
  } = useContext(SpaceContext)

  const [locationId, setLocationId] = useState()
  const [selectedOffices, setSelectedOffices] = useState([])
  const [selectedDesks, setSelectedDesks] = useState([])

  const locations =
    get(locationsCategorizedBySpaceOperator, "[0].spaces", []) || []

  useEffect(() => {
    if (!locationId) {
      setSelectedOffices(allOffices)
      setSelectedDesks(allDesks)
    } else {
      // filter the offices by location
      setSelectedOffices(
        allOffices.filter((office) => office.space_id === locationId)
      )
      setSelectedDesks(
        allDesks.filter((office) => office.space_id === locationId)
      )
    }
  }, [allOffices, locationId, allDesks])

  return (
    <Layout title={"Dashboard"} progress={!soData || !soData.id}>
      <div className={""}>
        <div className="form-group mb-5">
          <label>Location:</label>
          <select
            className={"form-control w-25"}
            onChange={(e) => setLocationId(e.target.value)}
            value={locationId}
          >
            <option value="">All Locations</option>
            {locations &&
              locations
                .filter(({ is_live }) => is_live)
                .map(({ id, space_name, ...others }) => (
                  <option value={id} key={id}>
                    {space_name}
                  </option>
                ))}
          </select>
        </div>
      </div>
      <InventoryAnalytics
        allDesks={selectedDesks}
        allOffices2={selectedOffices}
        allOffices={[
          ...selectedOffices.map(
            ({
              label,
              price,
              square_footage,
              occupant,
              space_available,
              space_id,
            }) => ({
              label,
              occupant,
              price,
              square_footage,
              space_available,
              space_id,
              type: "Office",
            })
          ),
          ...selectedDesks.map(
            ({
              label,
              price_per_desk,
              square_footage,
              occupant,
              space_available,
              space_id,
              desks_available = 1,
            }) => {
              return {
                label,
                occupant,
                price: price_per_desk * desks_available,
                square_footage,
                space_available,
                space_id,
                type: "Dedicated Desks",
              }
            }
          ),
        ]}
      />
    </Layout>
  )
}

export default InventoryAnalyticsTab
